export const checkConsistentJornadas = (recentRaces, setErrorMessage, setJornada) => {
  if (recentRaces.length === 0) return true;

  const firstJornada = recentRaces[0].jornada;
  const allSame = recentRaces.every(race => race.jornada === firstJornada);

  if (!allSame) {
    setErrorMessage('Error: Las jornadas no son consistentes en todas las carreras recientes.');
  } else {
    setJornada(firstJornada);
  }

  return allSame;
};

// export const calculateMultiplicador = (selectedHorses, baseMultiplicador) => 
//   selectedHorses.reduce((acc, curr) => acc * (curr.length || 1), baseMultiplicador);


  export const calculateMultiplicador = (selectedHorses, baseMultiplicador) => {
    console.log("selectedHorses",selectedHorses)
    console.log("baseMultiplicador",baseMultiplicador)
    const multiplicador = selectedHorses.reduce((acc, curr) => acc * (curr.length || 1), baseMultiplicador);
    return multiplicador;
  };

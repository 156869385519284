import React, { useState, useEffect } from "react";
import './components/utility_nfts.css';
//import axios from 'axios';
import { useTranslation } from 'react-i18next';

import Modal  from './components/modals/cofreclaimespecial.jsx';
import './components/cofres_especiales.css';
import { linkApp } from "./../generals/configuracion/variablesPublicas.jsx";












function CofresE() {
  const [isOpen, setIsOpen] = useState(false);
  const [cofresEProcesando, setCofresEProcesando] = useState({});
 // const [cofresEDisponibles, setCofresEDisponibles] = useState(null);
  const [walletData, setWalletData] = useState(null);
  //const [statuscofresE, setStatuscofresE] = useState(null);
  const { t } = useTranslation();






  useEffect(() => {
    const user = localStorage.getItem("user");




    // Simulación de retraso de medio segundo (500 milisegundos)
    const delay = 500;

    // Realizar la solicitud de Fetch aquí después del retraso
    setTimeout(() => {
      fetch(`${linkApp}/wallet/?usuario=${user}`)
        .then((response) => response.json())
        .then((data) => {
          const balanceDrops = data.body[0].balance_cofre_e;
          const retirosDrops = 0;//data.body[0].retiros_drops;
          const result = (balanceDrops - retirosDrops).toFixed(2);
          setWalletData(result);
        })
        .catch((error) => console.log(error));
    }, delay);
  }, []);

  console.log("walletData", walletData);







function handleClaim(reclamo, cofresEAReclamar) {

   let username=localStorage.getItem("user");
  if (cofresEAReclamar >= 1) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_cofres: { user_claim: username, cofres: 1 }})
    };
    fetch(`${linkApp}/cofre_e_delivery?user_cofres`, requestOptions)
      .then(response => response.json())
      .then(data => {console.log(data)
        //alert(data.body)
      if(data.error){
        alert(data.error);
      }else{
      setCofresEProcesando(data.body);
         setIsOpen(!isOpen);
      }
        })
      .catch(error => console.log(error));
  } else {
   alert(`${t("cofres.AlertaNoTienes")} ${reclamo} ${t("cofres.cofres")} `);
  }
}


const onClose = () => {
        setIsOpen(!isOpen);
  };



return (   
<div className="wrapp_especial">
  <div className="cofres-disponibles-especiales-title"> <h5>{t('cofreE.cofreE')}&nbsp; &nbsp;  <a href={t('wp.cofresEspeciales')} style={{ background: 'gold', borderRadius: '5px', color: 'black', padding: '0px', display: 'inline-block', float: 'right' }} title="More Info" target="_blank">
?
</a></h5>       </div>
  <div className="cofres-disponibles-especiales">
  <img src="image/cofres/cofre_especial.gif" alt='Chest'  />
   <h2>{walletData}</h2>
         <button
        type="button"
        onClick={() => handleClaim(1, walletData)}
        className="especial-chest"
      >
        <h2>{t('cofreE.claim')} </h2>
      </button>


  </div>
   
   

 
  <Modal isOpen={isOpen} onClose={onClose} cofresProcesando={cofresEProcesando} />

</div>
  );
}






export default CofresE
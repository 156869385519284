import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ActuacionesTable from './TablaActuaciones.jsx'; // Importa el componente de la tabla de actuaciones
import EquinosTable from './EquinosTable'; // Importa el componente de la tabla de equinos
import { linkApp } from "./../../../../../../generals/configuracion/variablesPublicas.jsx";
import Apuestas from './Apuestas.jsx';
import"./css/actuaciones.css";
import Loader from './../../../../../../generals/loders/Loader.jsx'; // Asegúrate de importar tu componente de loader
import RaceSimulator from "./../../../../races/simuladordecarrera.jsx";

const ActuacionesComponent = ({ raceId }) => {
  const [actuaciones, setActuaciones] = useState([]);
  const [visual, setVisual] = useState([]);
  const [apuestas, setApuestas] = useState([]);
  const [error, setError] = useState('');
  const [equinosC, setEquinosC] = useState([]);
  const [registro, setRegistro] = useState([]);
  const [showEquinos, setShowEquinos] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado para controlar la carga
  const [dataLoaded, setDataLoaded] = useState(false); // Estado para verificar si los datos ya han sido cargados
  const [equinosRegistrados, setEquinosRegistrados] = useState([]);


      console.log("equinosC",equinosC)
      console.log("registro",registro)
      console.log("registroEQ",equinosRegistrados)
      // console.log("Actuaciones",actuaciones)
      // console.log("Apuestas de fetch",apuestas) 
      // console.log("visual",visual)

  
//    useEffect(() => {
//     const fetchActuaciones = async () => {
//       try {
//         if (!dataLoaded) { // Verificar si los datos ya han sido cargados
//          const registro = await fetch(`${linkApp}/race?raceid=${raceId.raceid}`);
//           aca debe verificar el estado de la carrera y segun el estado cargar o no la data referente a las actuaciones

//           const response = await fetch(`${linkApp}/actuaciones?race=${raceId.raceid}`);
         
//           const data = await response.json();
//           const registros = await registro.json();
//           const registrados = await registros.body[0].registrados;

//           console.log("registradosSSSSS", registrados);
//           // Verificar si registros.body[0] está definido antes de acceder a visual
//           if (registros.body[0]) {
//             setVisual(registros.body[0].visual);
//             setRegistro(registrados)
//           }

//           console.log("Actuaciones Data", data);
//           console.log("Race Data", registros);
//           console.log("registrAdis", registros.body[0].registrados);

// if (registrados.length > 1) {
//   const numerosConsulta = registrados.join(',');
//   axios
//     .get(`${linkApp}/equino?equineId=${numerosConsulta}`)
//     .then((response) => {
//       const equinosNuevos = response.data.body;
//       setEquinosC((prevEquinosC) => {
//         // Filtrar equinosNuevos para eliminar duplicados
//         const equinosFiltrados = equinosNuevos.filter((nuevoEquino) => {
//           return !prevEquinosC.some((equinoExistente) => {
//             return equinoExistente.equineId === nuevoEquino.equineId;
//           });
//         });
//         // Combinar equinos existentes con equinos filtrados
//         return [...prevEquinosC, ...equinosFiltrados];
//       });
//       setShowEquinos(true);
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// }

//           if (data.error) {
//             setError(data.error);
//           } else {
//             setActuaciones(data.body);
//           }

//           setDataLoaded(true); // Marcar que los datos han sido cargados
//           setIsLoading(false); // Cambia isLoading a false una vez que los datos se cargan
//        }
//       } catch (error) {
//         setError('Error al cargar las actuaciones.');
//       }
//     };

//     if (raceId) {
//       fetchActuaciones();
//     }
//   }, [raceId, dataLoaded]); // Agregar dataLoaded como dependencia del efecto










// useEffect(() => {
//   const fetchRaceData = async () => {
//     try {
//       if (!dataLoaded) { // Verificar si los datos ya han sido cargados
//         const registroResponse = await fetch(`${linkApp}/race?raceid=${raceId.raceid}`);
//         const registros = await registroResponse.json();

//         // Verificar si registros.body[0] está definido y el estado de la carrera
//         if (registros.body[0]) {
//           const estadoCarrera = registros.body[0].status; // Estado de la carrera

//           if (estadoCarrera === 'close' || estadoCarrera === 'payed') {
//             // Si el estado es 'close' o 'payed', cargar las actuaciones
//             const actuacionesResponse = await fetch(`${linkApp}/actuaciones?race=${raceId.raceid}`);
//             const actuacionesData = await actuacionesResponse.json();
//             const registrados = registros.body[0].registrados;

//             console.log("Registrados:", registrados);
//             setVisual(registros.body[0].visual);
//             setRegistro(registrados);

//             if (registrados.length > 1) {
//               const numerosConsulta = registrados.join(',');
//               axios
//                 .get(`${linkApp}/equino?equineId=${numerosConsulta}`)
//                 .then((response) => {
//                   const equinosNuevos = response.data.body;
//                   setEquinosC((prevEquinosC) => {
//                     const equinosFiltrados = equinosNuevos.filter((nuevoEquino) => {
//                       return !prevEquinosC.some((equinoExistente) => {
//                         return equinoExistente.equineId === nuevoEquino.equineId;
//                       });
//                     });
//                     return [...prevEquinosC, ...equinosFiltrados];
//                   });
//                   setShowEquinos(true);
//                 })
//                 .catch((error) => {
//                   console.error(error);
//                 });
//             }

//             if (actuacionesData.error) {
//               setError(actuacionesData.error);
//             } else {
//               setActuaciones(actuacionesData.body);
//             }

//           } else {
//             // Si el estado de la carrera no es 'close' ni 'payed', cargar solo los registros
//             const registrados = registros.body[0].registrados;

//             if (registrados.length > 1) {
//               const numerosConsulta = registrados.join(',');
//               axios
//                 .get(`${linkApp}/equino?equineId=${numerosConsulta}`)
//                 .then((response) => {
//                   const equinosNuevos = response.data.body;
//                   setEquinosC((prevEquinosC) => {
//                     const equinosFiltrados = equinosNuevos.filter((nuevoEquino) => {
//                       return !prevEquinosC.some((equinoExistente) => {
//                         return equinoExistente.equineId === nuevoEquino.equineId;
//                       });
//                     });
//                     return [...prevEquinosC, ...equinosFiltrados];
//                   });
//                   setShowEquinos(true);
//                 })
//                 .catch((error) => {
//                   console.error(error);
//                 });
//             }

//             console.log("La carrera no está en estado 'close' o 'payed'. Solo se cargan los registros.");
//           }

//           setDataLoaded(true); // Marcar que los datos han sido cargados
//           setIsLoading(false); // Cambia isLoading a false una vez que los datos se cargan
//         }
//       }
//     } catch (error) {
//       setError('Error al cargar los datos.');
//     }
//   };

//   if (raceId) {
//     fetchRaceData();
//   }
// }, [raceId, dataLoaded]); // Agregar dataLoaded como dependencia del efecto




useEffect(() => {
  const fetchRaceData = async () => {
    try {
      if (!dataLoaded) { // Verificar si los datos ya han sido cargados
        const registroResponse = await fetch(`${linkApp}/race?raceid=${raceId.raceid}`);
        const registros = await registroResponse.json();
 console.log("registroEQ XXXX", registros.body[0])
        // Verificar si registros.body[0] está definido y el estado de la carrera
        if (registros.body[0]) {
          const estadoCarrera = registros.body[0].status; // Estado de la carrera

          if (estadoCarrera === 'close' || estadoCarrera === 'payed') {
            // Si el estado es 'close' o 'payed', cargar las actuaciones
            const actuacionesResponse = await fetch(`${linkApp}/actuaciones?race=${raceId.raceid}`);
            const actuacionesData = await actuacionesResponse.json();
            const registrados = registros.body[0].registrados;

            console.log("Registrados:", registrados);
            setVisual(registros.body[0].visual);
            setRegistro(registrados);

            if (registrados.length > 1) {
              const numerosConsulta = registrados.join(',');
              axios
                .get(`${linkApp}/equino?equineId=${numerosConsulta}`)
                .then((response) => {
                  const equinosNuevos = response.data.body;
                  setEquinosC((prevEquinosC) => {
                    const equinosFiltrados = equinosNuevos.filter((nuevoEquino) => {
                      return !prevEquinosC.some((equinoExistente) => {
                        return equinoExistente.equineId === nuevoEquino.equineId;
                      });
                    });
                    return [...prevEquinosC, ...equinosFiltrados];
                  });
                  setShowEquinos(true);
                })
                .catch((error) => {
                  console.error(error);
                });
            }

            if (actuacionesData.error) {
              setError(actuacionesData.error);
            } else {
              setActuaciones(actuacionesData.body);
            }

          } else {
            // Si el estado de la carrera no es 'close' ni 'payed', cargar solo los registros
            const registrados = registros.body[0].registrados;

if (registrados.length > 1) {
  const numerosConsulta = registrados.join(',');
  axios
    .get(`${linkApp}/equino?equineId=${numerosConsulta}`)
    .then((response) => {
      const equinosNuevos = response.data.body || [];

      console.log('Respuesta de equinos:', equinosNuevos);

      setEquinosC((prevEquinosC) => {
        // Combinar datos nuevos y existentes, evitando duplicados
        const combinados = [
          ...prevEquinosC,
          ...equinosNuevos.filter(
            (nuevoEquino) =>
              !prevEquinosC.some(
                (equinoExistente) => equinoExistente.equineId === nuevoEquino.equineId
              )
          ),
        ];

        console.log('Equinos combinados (sin ordenar):', combinados);

        // Ordenar según el orden en "registrados"
        const ordenados = registrados.map((id) => {
          const encontrado = combinados.find((equino) => String(equino.equineId) === id);
          if (!encontrado) {
            console.warn(`El ID ${id} en registrados no tiene coincidencia en los datos obtenidos.`);
          }
          return encontrado || { equineId: id, missing: true }; // Asegurar que cada ID tenga una entrada
        });

        console.log('Equinos ordenados:', ordenados);

        // Retornar solo los datos válidos (puedes ajustar si necesitas incluir los faltantes)
        return ordenados.filter((equino) => !equino.missing);
      });

      setShowEquinos(true); // Mostrar los equinos
    })
    .catch((error) => {
      console.error('Error en la consulta:', error);
    });
}




            console.log("La carrera no está en estado 'close' o 'payed'. Solo se cargan los registros.");
          }

          setDataLoaded(true); // Marcar que los datos han sido cargados
          setIsLoading(false); // Cambia isLoading a false una vez que los datos se cargan
        }
      }
    } catch (error) {
      setError('Error al cargar los datos.');
    }
  };

  if (raceId) {
    fetchRaceData();
  }
}, [raceId, dataLoaded]); // Agregar dataLoaded como dependencia del efecto



  

useEffect(() => {
  // Verificar si equinosC y registro están cargados
  if (equinosC.length > 0 && registro.length > 0) {
    const nuevoArrayEquinosRegistrados = [];
    registro.forEach((registroId, index) => {
      const equino = equinosC.find(equino => Number(equino.equineId) === Number(registroId));
      if (equino) {
        nuevoArrayEquinosRegistrados.push({
          name: equino.nameBhr,
          points: equino.equineId
        });
      }
    });
    setEquinosRegistrados(nuevoArrayEquinosRegistrados);
  }
}, [equinosC.length, registro.length]);



  const fetchApuestas = async () => {
    try {
      console.log(raceId);
      const response = await fetch(`${linkApp}/bets?race=${raceId.raceid}`);
      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setApuestas(data.body[0]);
      }
    } catch (error) {
      setError('Error al cargar las Apuestas.');
    }
  };

  useEffect(() => {
    // Aquí puedes definir cómo cambia raceId
    // ...

    // Iniciar la búsqueda de apuestas
    fetchApuestas();

      // Programar la próxima llamada a fetchApuestas después de 30 segundos
  const fetchInterval = setInterval(fetchApuestas, 40000);

  // Devolvemos una función de limpieza para detener el intervalo cuando el componente se desmonta
  return () => clearInterval(fetchInterval);
  }, []);




  // Renderiza el contenido aquí
return (
  <div className="tabla-actuaaciones">
    {error && <p className="error">{error}</p>}


    {isLoading ? (
      // Muestra el loader mientras se cargan los datos
      <Loader />
    ) : (
      (actuaciones && actuaciones.length && equinosRegistrados.length > 0) ? (
    <>
       {visual && visual.length > 0 && <RaceSimulator visual={{ visual: visual }} corredores={{ corredores: equinosRegistrados }} />}
     

      <ActuacionesTable actuaciones_base={{ actuaciones: actuaciones, apuestas: apuestas }} />
    </>
  ) : (
    showEquinos && equinosC.length > 0 && <EquinosTable equinos={{ equinosT: equinosC, raceId: raceId.raceid,raceName:raceId.name, apuestas: apuestas }} fetchApuestas={fetchApuestas} />
  )
    )}
  </div>
);

};





export default ActuacionesComponent;




 //Posible borrar pronto
  // useEffect(() => {
  //   const fetchActuaciones = async () => {
  //     let visual=[]
  //     try {
  //       console.log(raceId.raceid);
  //       const response = await fetch(`${linkApp}/actuaciones?race=${raceId.raceid}`);
  //       const registro = await fetch(`${linkApp}/race?raceid=${raceId.raceid}`);
  //       const data = await response.json();
  //       const registros = await registro.json();
  //       const registrados = await registros.body[0].registrados;
      
  //     console.log("registradosSSSSS",registrados)
  //   // Verificar si registrados.body[0] está definido antes de acceder a visual
  //   if (registros.body[0]) {
  //       visual = registros.body[0].visual;

  //   }

  //   setVisual(visual);

        
  //       console.log("Actuaciones Data", data);
  //       console.log("Race Data", registros);
  //       console.log("registrAdis", registros.body[0].registrados);

  //       if (registrados.length > 1) {
  //         const numerosConsulta = registrados.join(',');
  //         axios
  //           .get(`${linkApp}/equino?equineId=${numerosConsulta}`)
  //           .then((response) => {
  //             const equinos = response.data.body;
  //             setEquinosC((prevEquinosC) => [...prevEquinosC, ...equinos]);
  //             setShowEquinos(true);
  //           })
  //           .catch((error) => {
  //             console.error(error);
  //           });
  //       }

  //       if (data.error) {
  //         setError(data.error);
  //       } else {
  //         setActuaciones(data.body);
  //       }
  //     } catch (error) {
  //       setError('Error al cargar las actuaciones.');
  //     }
  //   };

  //   if (raceId) {
  //     fetchActuaciones();
  //   }
  // }, [raceId]);



// useEffect(() => {
//   // Verificar si equinosC y registro están cargados
//   if (equinosC.length > 0 && registro.length > 0) {
//     const nuevoArrayEquinosRegistrados = [];
//     registro.forEach((registroId, index) => {
//       const equino = equinosC.find(equino => Number(equino.equineId) === Number(registroId));
//       if (equino) {
//         nuevoArrayEquinosRegistrados.push({
//           name: equino.nameBhr,
//           points: equino.equineId
//         });
//       }
//     });
//     setEquinosRegistrados(nuevoArrayEquinosRegistrados);
//   }
// }, [equinosC.length, registro.length]);






const RaceSelection = ({ race, raceIndex, selectedHorses, handleSelectHorse, getRaceLink }) => (
  <div className="race-selection">
    <h3>
      Race - {race.raceid}
      <a
        href={getRaceLink(race.raceid)}
        className="info-icon"
        target="_blank"
        rel="noopener noreferrer"
        title="Ver detalles de la carrera"
      >
        ℹ️
      </a>
    </h3>
    <ul className="horse-options">
      {Array.from({ length: 8 }).map((_, horseNumber) => (
        <li key={horseNumber}>
          <label>
            <input
              type="checkbox"
              value={horseNumber}
              checked={selectedHorses[raceIndex].includes(horseNumber)}
              onChange={() => handleSelectHorse(raceIndex, horseNumber)}
            />
            {horseNumber}
          </label>
        </li>
      ))}
    </ul>
  </div>
);

export default RaceSelection;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './componentes/css/ModalPremios.css';
import { useTranslation } from 'react-i18next';
import { linkApp } from './../../../../../generals/configuracion/variablesPublicas.jsx';
import Acumulados from './componentes/modules/Acumulados';
import PremiosUnicos from './componentes/modules/PremiosUnicos.jsx';
import PremiosDetallados from './componentes/modules/PremiosDetallados.jsx';
import JornadaSearch from './componentes/modules/JornadaSearch.jsx';

import Header from './componentes/modules/Header.jsx';
import Ganadores from './componentes/modules/Ganadores.jsx'; // Importar el módulo de ganadores



// Dentro del renderizado de tu componente principal


const ModalPremiosJ = ({ isOpenPremiosJ, closeModalPremiosJ, recentRaces }) => {
  const [jornadaData, setJornadaData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchJornada, setSearchJornada] = useState('');
  const { t } = useTranslation();


  const onSearchClick = () => {
    if (searchJornada) {
      fetchJornadaData(searchJornada);
    }
  };

  const checkConsistentJornadas = () => {
    if (recentRaces.length === 0) return true;
    const firstJornada = recentRaces[0].jornada;
    const allSame = recentRaces.every(race => race.jornada === firstJornada);

    if (!allSame) {
      setErrorMessage('Error: Inconsistent Races Days. Reload the page and try again..');
      return false;
    }

    setSearchJornada(firstJornada); // Establece el número de jornada en el buscador
    return true;
  };

  const fetchJornadaData = async (jornada) => {
    try {
      const response = await axios.get(`${linkApp}/jornada_5y6?jornada=${jornada}`);
      console.log("Respuesta desde Jornada app data ", response.data.body.data);
      setJornadaData(response.data.body.data); // Almacena los datos de la jornada
      setErrorMessage(''); // Limpia los errores previos si la solicitud fue exitosa
    } catch (error) {
      setErrorMessage("The information couldn't be retrieved. Please try again");
      setJornadaData(null); // Limpia los datos si hay un error
    }
  };

  useEffect(() => {
    if (isOpenPremiosJ) {
      const isConsistent = checkConsistentJornadas();
      if (isConsistent && searchJornada) {
        fetchJornadaData(searchJornada);
      }
    }
  }, [isOpenPremiosJ, recentRaces]);

  const handleSearchChange = (e) => {
    setSearchJornada(e.target.value);
  };

  const handleSearchJornada = (e) => {
    if (e.key === 'Enter' && searchJornada) {
      fetchJornadaData(searchJornada);
    }
  };

  return (
    <div className={`modalPremioJ-overlay ${isOpenPremiosJ ? 'is-open' : ''}`}>
      <div className="modalPremioJ-content">
        <div className="modal-header">
        <button onClick={closeModalPremiosJ} className="close-button">
          X
        </button>
          <h2>{t('racein.ModalInfo.Jornadas')}{searchJornada}</h2>

            <a
            href="https://docs-en.blockhorseracing.com/additional-game/6-in-a-row"
            className="rules-button-premiosJ"
            target="_blank"
            rel="noopener noreferrer"
          >
            📑Rules
          </a>
        

    
</div>
           <JornadaSearch 
          searchJornada={searchJornada}
          handleSearchChange={handleSearchChange}
          handleSearchJornada={handleSearchJornada}
          onSearchClick={onSearchClick}
        />
        {jornadaData ? (
          <div className="jornada-details">

                <Acumulados jornadaData={jornadaData} />
              
              
              {(jornadaData.status === "completa"  || jornadaData.status === "close") ?(
                <PremiosDetallados  jornadaData={jornadaData} />
               
                ):( <PremiosUnicos jornadaData={jornadaData} />

                )}
                
                <Ganadores eq_ganadores={jornadaData.eq_ganadores} />

            <div className="section tickets">
              <p>{t('racein.jornada_5y6.info.TicketsSellados')}:  {jornadaData.tickets.length}</p>
            
              <p>{t('general.Races')}: {jornadaData.carreras.map((carrera) => carrera.newid).join(', ')}</p>
              <p>{t('general.Torneo')}: {jornadaData.torneo}</p>

            </div>

         

          </div>
        ) : (
          <p>{errorMessage || 'Loading race day information...'}</p>
        )}

      
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default ModalPremiosJ;












 // const linkApp ='http://localhost:4000'; 
 const linkApp = '';
 const consoleEnabled = false;
; // Renombrado

// Exportar los elementos como nombrados
export { consoleEnabled as consol, linkApp };




// transaccionKeychain.js
import axios from 'axios';

// export const enviarTransaccionKeychain = (user, CuentaPool, costoRegistro, memo, customJson, closeModal, setErrorMessage) => {
//   if (!window.hive_keychain) {
//     setErrorMessage('Hive Keychain no está instalado');
//     return;
//   }

//   window.hive_keychain.requestSendToken(
//     user,
//     CuentaPool,
//     costoRegistro.toFixed(3),
//     memo,
//     'SWAP.HIVE',
//     (response) => {
//       if (response.error) {
//         setErrorMessage('Error al realizar la transacción: ' + response.error);
//       } else {
//         axios.post('/api/transaction', {
//           customJson,
//           costo_registro: costoRegistro,
//           CuentaPool,
//         })
//         .then(res => {
//           console.log('Transacción enviada con éxito:', res.data);
//           closeModal(); // Cierra el modal después de comprar
//         })
//         .catch(err => {
//           console.error('Error al enviar la transacción al backend:', err);
//         });
//       }
//     }
//   );
// };






export const enviarTransaccionKeychain = (user, CuentaPool, costoRegistro, memo, customJson, closeModal, setErrorMessage, selectedToken) => {

console.log("Debiannnnnnnnnnnnnnnnn",user, CuentaPool, costoRegistro, memo, customJson,selectedToken)

  const transferOperation = {
    contractName: 'tokens',
    contractAction: 'transfer',
    contractPayload: {
      symbol: selectedToken,
      to: CuentaPool,
      quantity: costoRegistro.toFixed(3),
      memo: memo
    }
  };

  window.hive_keychain.requestCustomJson(
    user,
    'ssc-mainnet-hive',
    'Active',
    JSON.stringify(transferOperation),
    'Hive Engine Token Transfer',
    (response) => {
      if (response.success) {
        closeModal();
      } else {
        setErrorMessage('Error en la transacción: ' + response.message);
      }
    }
  );
};

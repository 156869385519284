// src/components/TokenSelector.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';
import './css/TokenSelector.css';
import logos from './../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';

const TokenSelector = ({ selectedToken, setSelectedToken, availableTokens }) => {
  const { t } = useTranslation();

  return (
    <div className="token-selector">
      <label htmlFor="token-select">{t('SellarBoleto.SelectToken')}:</label>
      <select 
        id="token-select" 
        value={selectedToken} 
        onChange={(e) => setSelectedToken(e.target.value)}
      >
        {availableTokens.map(token => (
          <option key={token} value={token}>{token}</option>
        ))}
      </select>
      <a
        href="https://tribaldex.com/swap?from=SWAP.HIVE&to=BHRT"
        className="buy-token-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('SellarBoleto.BuyBHRT')}
             <img
              src={logos}
              alt="token-logo"
              className="coin-logo"
            />
      </a>
    </div>
  );
};

export default TokenSelector;

// horseSelectionUtils.js
export const handleSelectHorse = (selectedHorses, raceIndex, horseNumber) => {
  const newSelectedHorses = [...selectedHorses];
  const raceSelections = newSelectedHorses[raceIndex];

  if (raceSelections.includes(horseNumber)) {
    newSelectedHorses[raceIndex] = raceSelections.filter(num => num !== horseNumber);
  } else {
    newSelectedHorses[raceIndex] = [...raceSelections, horseNumber];
  }

  return newSelectedHorses;
};

// src/hooks/useMultiplier.js
import { useState, useEffect } from 'react';
import axios from 'axios';

const baseMultipliers = {
  'SWAP.HIVE': 0.02,
  'BHRT': 0.017
};

const fetchPoolData = async () => {
  try {
    const response = await axios.post('https://dswap-api.dswap.trade/api/SwapRequest/CalculateSwapOutput', {
      TokenInput: "BHRT",
      TokenInputAmount: 1,
      TokenOutput: "SWAP.HIVE",
      Chain: 1
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching pool data:', error);
    return null;
  }
};

const calculateBHRTPrice = (responseData) => {
  if (!responseData || !responseData.BaseTokenAmount) return 1;
  return parseFloat(responseData.BaseTokenAmount);
};

const calculateMultiplier = (baseMultiplier, selectedToken, poolData) => {
  if (selectedToken === 'SWAP.HIVE') {
    return baseMultiplier;
  } else if (selectedToken === 'BHRT') {
    const bhrtPrice = calculateBHRTPrice(poolData);
    return baseMultiplier / bhrtPrice;
  }
  return baseMultiplier;
};

export const useMultiplier = (initialToken) => {
  const [selectedToken, setSelectedToken] = useState(initialToken);
  const [multiplier, setMultiplier] = useState(baseMultipliers[initialToken]);

  useEffect(() => {
    const updateMultiplier = async () => {
      const poolData = await fetchPoolData();
      const baseMultiplier = baseMultipliers[selectedToken];
      const newMultiplier = calculateMultiplier(baseMultiplier, selectedToken, poolData);
      setMultiplier(newMultiplier);
    };

    updateMultiplier();
  }, [selectedToken]);

  return { selectedToken, setSelectedToken, multiplier };
};

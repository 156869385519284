// src/utils/handleBuyHorses.js
import { validarSeleccionCaballos, validarAutenticacionYJornada } from '../components/validaciones';
import { construirMemo } from '../components/construirMemo';
import { enviarTransaccionKeychain } from '../components/transaccionKeychain';
import { calculateMultiplicador } from './modalUtils';

export const handleBuyTickets = async ({
  selectedHorses,
  multiplier,
  jornada,
  recentRaces,
  selectedToken,
  setErrorMessage,
  closeModal
}) => {
  setErrorMessage('');
  console.log("selectedHorses,selectedHorses", selectedHorses);

  const errorSeleccion = validarSeleccionCaballos(selectedHorses);
  if (errorSeleccion) {
    setErrorMessage(errorSeleccion);
    return;
  }

  const calculatedMultiplier = calculateMultiplicador(selectedHorses, multiplier);
  if (!calculatedMultiplier) {
    setErrorMessage('El multiplicador calculado no es válido.');
    return;
  }
  console.log("Multiplicador es ", calculatedMultiplier);
  console.log("Jornada ", jornada);

  const { error, user } = validarAutenticacionYJornada(jornada);
  if (error) {
    setErrorMessage(error);
    return;
  }

  const customJson = {
    selectedHorses,
    multiplier: calculatedMultiplier,
  };

  const costoRegistro = calculatedMultiplier;
  const CuentaPool = 'bhr-pick6';

  const memo = construirMemo(recentRaces, selectedHorses, costoRegistro, jornada);

  enviarTransaccionKeychain(user, CuentaPool, costoRegistro, memo, customJson, closeModal, setErrorMessage, selectedToken);
};

import React, { useState, useEffect } from 'react';
import './ModalSellarBoleto.css';
import { useTranslation } from 'react-i18next';
import { checkConsistentJornadas, calculateMultiplicador } from './utils/modalUtils.jsx';
import { handleBuyTickets } from './utils/handleBuyTickets.jsx';
import { handleSelectHorse } from './utils/horseSelectionUtils.jsx';
import { useMultiplier } from './hooks/useMultiplier';
import RaceSelection from './components/RaceSelection';
import TokenSelector from './components/TokenSelector';

const ModalSellarBoleto = ({ isOpen, closeModal, recentRaces }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedHorses, setSelectedHorses] = useState(recentRaces.map(() => []));
  const [jornada, setJornada] = useState("");
  const { selectedToken, setSelectedToken, multiplier } = useMultiplier('SWAP.HIVE');

  const availableTokens = ['SWAP.HIVE', 'BHRT'];
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      const isConsistent = checkConsistentJornadas(recentRaces, setErrorMessage, setJornada);
      if (!isConsistent) {
        closeModal();
      }
    }
  }, [isOpen, recentRaces, closeModal]);

  const onSelectHorse = (raceIndex, horseNumber) => {
    const newSelectedHorses = handleSelectHorse(selectedHorses, raceIndex, horseNumber);
    setSelectedHorses(newSelectedHorses);
  };

  const onBuyTickets = () => {
    handleBuyTickets({
      selectedHorses,
      multiplier,
      jornada,
      recentRaces,
      selectedToken,
      setErrorMessage,
      closeModal
    });
  };

  const getRaceLink = (raceId) => {
    return `https://www.blockhorseracing.com/races/#raceId=${raceId}`;
  };

  if (!isOpen) return null;

  return (
    <div className="modalboleto-overlay">
      <div className="modalboleto-content">
        <div className="modal-header">
          <h2>{t('SellarBoleto.title')}</h2>
          <a
            href="https://docs-en.blockhorseracing.com/additional-game/6-in-a-row"
            className="rules-button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('SellarBoleto.Rules')}
          </a>
        </div>
        <h4>{t('SellarBoleto.Jornada')} {jornada}</h4>

          <TokenSelector
          selectedToken={selectedToken}
          setSelectedToken={setSelectedToken}
          availableTokens={availableTokens}
        />
      <h2>{t('SellarBoleto.discountText')}</h2>


        {recentRaces.some(race => race.status_superior !== 'abierta') ? (
          <div className="warning-message">
            {t('SellarBoleto.JugadaClose')}
          </div>
        ) : (
          <>
            {recentRaces.map((race, raceIndex) => (
              <RaceSelection
                key={race.raceid}
                race={race}
                raceIndex={raceIndex}
                selectedHorses={selectedHorses}
                handleSelectHorse={onSelectHorse}
                getRaceLink={getRaceLink}
              />
            ))}

            <p>Multiplier: {multiplier.toFixed(3)} {selectedToken}</p>
            <p>Total: {calculateMultiplicador(selectedHorses, multiplier).toFixed(3)} {selectedToken}</p>
            <button onClick={onBuyTickets} className="buy-button">
              {t('SellarBoleto.SealTicket')}
            </button>
          </>
        )}

        <button onClick={closeModal} className="close-button">
          {t('general.Close')}
        </button>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default ModalSellarBoleto;







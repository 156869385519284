import React, { useState, useMemo } from 'react';
import './../css/PremiosUnicos.css'; // Importa los estilos específicos para el componente

import logos from './../../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';
import hivelogo from './../../../../../../../../assets/logoblockhorseracing/logoHive.png';
import logosBHR from './../../../../../../../../assets/logoblockhorseracing/logoBlock.png';
import { useTranslation } from 'react-i18next';

const Premios = ({ jornadaData }) => {
  const [isOpen, setIsOpen] = useState([false, false, false]); // Control de los acordeones
  const { t } = useTranslation();


// Variable para el prefijo de la URL
const urlPrefix = "https://example.com/ticket/"; // Cambia esto según sea necesario

  const premios = useMemo(() => {
    const acumulado1Total = Number(jornadaData.acumulado_1) + Number(jornadaData.pote_1);
    const acumulado2Total = Number(jornadaData.acumulado_2) + Number(jornadaData.pote_2);
    const acumulado3Total = Number(jornadaData.acumulado_3) + Number(jornadaData.pote_3);

    return {
      premio_unico_6: [
        jornadaData.premio_1_final[0].premio_1.toFixed(2),
        jornadaData.premio_1_final[0].premio_2.toFixed(2),
        jornadaData.premio_1_final[0].premio_3.toFixed(2),
      ],
      premio_unico_5: [
        jornadaData.premio_2_final[0].premio_1.toFixed(2),
        jornadaData.premio_2_final[0].premio_2.toFixed(2),
        jornadaData.premio_2_final[0].premio_3.toFixed(2),
      ],
      premio_unico_4: [
        jornadaData.premio_3_final[0].premio_1.toFixed(2),
        jornadaData.premio_3_final[0].premio_2.toFixed(2),
        jornadaData.premio_3_final[0].premio_3.toFixed(2),
      ],
        premio_unico_3: [
                jornadaData.premio_3_final[0].premio_1.toFixed(2),

      ],
    };
  }, [jornadaData]);

  const toggleAccordion = (index) => {
    setIsOpen((prevState) => prevState.map((open, i) => (i === index ? !open : open)));
  };

  const getLogo = (symbol) => {
    switch (symbol) {
      case 'BHRT':
        return logos;
      case 'BHR':
        return logosBHR;
      case 'SWAP.HIVE':
        return hivelogo;
      default:
        return null;
    }
  };

  const ganadoresData = [
    jornadaData.ganadores_6,
    jornadaData.ganadores_5,
    jornadaData.ganadores_4,
    jornadaData.ganadores_3,
  ];

  return (
    <div className="section premios">
      <div className="premios-title">{t("racein.jornada_5y6.premios-D")}</div>

      <div className="premios-row-container">
        {['6 ✅', '5 ✅', '4 ✅','3 ✅'].map((acierto, index) => (
          <div
            key={index}
            className={`premios-card ${isOpen[index] ? 'open' : ''}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="card-header">
              <span>{acierto}</span>
              <span className="arrow">{isOpen[index] ? '↑' : '↓'}</span>
            </div>

            {isOpen[index] && (
              <div className="card-body">
                {[0, 1, 2].map((i) => (
                  <div key={i} className="premios-row">
                    <img
                      src={getLogo(jornadaData[`symbol_${i + 1}`])}
                      alt={jornadaData[`symbol_${i + 1}`]}
                      className="coin-logo"
                    />
                    <div>{premios[`premio_unico_${acierto.charAt(0)}`][i]}</div>
                  </div>
                ))}
   <div className="ganadores-info">
  <p><strong>{t("racein.jornada_5y6.Total-tickets")}:</strong> {ganadoresData[index]?.length || 0}</p>
  <p><strong>Ticket Hashs:</strong></p>
  <div>
    {ganadoresData[index]?.length > 0 ? (
      ganadoresData[index].map((id, idx) => (
        <a
          key={idx}
          href={`${urlPrefix}${id}`}
          target="_blank"
          rel="noopener noreferrer"
          className="ticket-link"
        >
          {id.slice(-5)}
        </a>
      )).reduce((prev, curr) => [prev, ', ', curr])
    ) : (
      <p>No hay tickets disponibles.</p> // Mensaje cuando el array está vacío
    )}
  </div>
</div>


              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};




export default Premios;

// Card.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { linkApp } from './../../../../generals/configuracion/variablesPublicas.jsx';

import './Card.css'; // Estilos para tu tarjeta


const CardVentaEspecial = ({ imgSrc, itemName, onBuy }) => {
  const [purchasedItems, setPurchasedItems] = useState([]);



  useEffect(() => {
    const user = localStorage.getItem("user");

    // Simulación de retraso de medio segundo (500 milisegundos)
    const delay = 500;

    // Realizar la solicitud fetch aquí después del retraso
    setTimeout(() => {
      fetch(`${linkApp}/ventas_especiales/?usuario=${user}`)
        .then((response) => response.json())
        .then((data) => {
          console.log("data_venta_espaciales",data)
          // Mapea los datos para incluir la imagen y el nombre del ítem
          const items = data.body.map((item) => ({
            img: `image/venta_especial/${item.rarezaObtenida}.png`, // Ajusta esto según tu lógica para las imágenes
            name:`${item.rarezaObtenida} pack`,
          }));
          setPurchasedItems(items);
        })
        .catch((error) => console.log(error));
    }, delay);
  }, []);






const getExchangeRate = async () => {
  try {
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=hive&vs_currencies=usd');
    const data = await response.json();
    return data.hive.usd; // Obtén el precio en USD
  } catch (error) {
    console.error('Error al obtener el precio de HIVE:', error);
    return null;
  }
};


const handlebuyVenta = async () => {
  const memo = `Special Anniversary Entry Random Pack for Block Horse Racing`;
  const toUsername = "bhr-especiapackl";
  const username = localStorage.getItem("user");
  
  const exchangeRate = await getExchangeRate();
  if (!exchangeRate) {
    alert('No se pudo obtener la tasa de cambio.');
    return;
  }
  
  const amountInUSD = 15;
  const amount = (amountInUSD / exchangeRate).toFixed(3); // Calcula el monto en tokens SWAP.HIVE
  
  if (window.hive_keychain) {
    const keychain = window.hive_keychain;
    keychain.requestSendToken(username, toUsername, amount, memo, 'SWAP.HIVE', (response) => {
      console.log("RES KeyC", response);
      axios.post(`${linkApp}/keychainchestbhrt`, response)
        .then(res => {
          console.log("Response from backend", res);
          // Manejar la respuesta del backend si es necesario
        })
        .catch(error => {
          console.log("Error de post axios", error);
          // Manejar cualquier error que ocurra durante la llamada a la API
        });
    });
  } else {
    alert('You do not have hive keychain installed');
  }
};



  return (
    <div className="carta">
      <div className="carta-content">
        <div className="left-column">
          <img src="image/venta_especial/specialpack.gif" alt={itemName} className="carta-image" />
          <h3>{itemName}</h3>
          {/*<button onClick={handlebuyVenta}>Buy</button>*/}
             <button onClick={handlebuyVenta} disabled={purchasedItems.length >= 5}>Buy</button>
       
        </div>
        <div className="right-column">
          <h4>Recently Purchased</h4>
          <ul>
 {purchasedItems.map((item, index) => (
        <li key={index}>
          <img src={item.img} alt={item.name} className="purchased-image" />
          {item.name}
        </li>
      ))}          
 </ul>
        </div>
      </div>
    </div>
  );
};

export default CardVentaEspecial;









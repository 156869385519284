import React, { useState, useMemo } from 'react';
import './../css/PremiosUnicos.css'; // Importa los estilos específicos para el componente

import logos from './../../../../../../../../assets/logoblockhorseracing/coinBHRT2.png';
import hivelogo from './../../../../../../../../assets/logoblockhorseracing/logoHive.png';
import logosBHR from './../../../../../../../../assets/logoblockhorseracing/logoBlock.png';
import { useTranslation } from 'react-i18next';

const Premios = ({ jornadaData }) => {
  const [isOpen, setIsOpen] = useState([false, false, false]); // Control de los acordeones
  const { t } = useTranslation();

console.log(jornadaData)
  const premios = useMemo(() => {
    // Calcula los totales acumulados sumando acumulado_X y pote_X
    const acumulado1Total = Number(jornadaData.acumulado_1) + Number(jornadaData.pote_1);
    const acumulado2Total = Number(jornadaData.acumulado_2) + Number(jornadaData.pote_2);
    const acumulado3Total = Number(jornadaData.acumulado_3) + Number(jornadaData.pote_3);

    return {
      premio_unico_6: [
        (acumulado1Total * 0.8).toFixed(2),
        (acumulado2Total * 0.8).toFixed(2),
        (acumulado3Total * 0.8).toFixed(2),
      ],
      premio_unico_5: [
        (acumulado1Total * 0.1).toFixed(2),
        (acumulado2Total * 0.1).toFixed(2),
        (acumulado3Total * 0.1).toFixed(2),
      ],
      premio_unico_4: [
        (acumulado1Total * 0.1).toFixed(2),
        (acumulado2Total * 0.1).toFixed(2),
        (acumulado3Total * 0.1).toFixed(2),
      ],
    };
  }, [jornadaData]);

  const toggleAccordion = (index) => {
    setIsOpen((prevState) => prevState.map((open, i) => (i === index ? !open : open)));
  };


  const getLogo = (symbol) => {
    switch (symbol) {
      case 'BHRT':
        return logos;
      case 'BHR':
        return logosBHR;
      case 'SWAP.HIVE':
        return hivelogo;
      default:
        return null;
    }
  };

  return (
    <div className="section premios">
      <div className="premios-title">{t("racein.jornada_5y6.premios-U")}</div>

      <div className="premios-row-container">
        {['6 ✅', '5 ✅', '4 ✅'].map((acierto, index) => (
          <div
            key={index}
            className={`premios-card ${isOpen[index] ? 'open' : ''}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="card-header">
              <span>{acierto}</span>
              <span className="arrow">{isOpen[index] ? '↑' : '↓'}</span>
            </div>

            {isOpen[index] && (
              <div className="card-body">
                {[0, 1, 2].map((i) => (
                  <div key={i} className="premios-row">
                      <img
              src={getLogo(jornadaData[`symbol_${i + 1}`])}
              alt={jornadaData[`symbol_${i + 1}`]}
              className="coin-logo"
            />
                    <div>{premios[`premio_unico_${acierto.charAt(0)}`][i]}</div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Premios;


import React, { useEffect,useState } from 'react';
import axios from 'axios';


import ballo11 from'../../../../assets/ballo11.png'
import ballo12 from'../../../../assets/ballo12.png'
import ballo13 from'../../../../assets/ballo13.png'
import ballo14 from'../../../../assets/ballo14.png'
import ballo15 from'../../../../assets/ballo15.png'
import ballo16 from'../../../../assets/ballo16.png'
import ballo17 from'../../../../assets/ballo17.png'
import { linkApp } from './../../../generals/configuracion/variablesPublicas.jsx';
import './presale.css'
import Especial from './ventaespecial/ventaspecial.jsx';


function Presale() {

   const [equinosPoblacion, setEquinosPoblacion] = useState({});
   const [equinosPoblacionL, setEquinosPoblacionL] = useState({});
   const [equinosPoblacionM, setEquinosPoblacionM] = useState({});
   const [equinosPoblacionE, setEquinosPoblacionE] = useState({});
   const [equinosPoblacionS, setEquinosPoblacionS] = useState({});
   const [equinosPoblacionR, setEquinosPoblacionR] = useState({});
   const [equinosPoblacionU, setEquinosPoblacionU] = useState({});
   const [equinosPoblacionC, setEquinosPoblacionC] = useState({});



  

  useEffect(() => {
    const fetchEquinos = async () => {
      try {
        const response = await fetch(`${linkApp}/poblacion`);
        const equinos = await response.json();
        console.log("equinos", equinos);
        
        // Actualiza el estado con los datos obtenidos
        setEquinosPoblacion(equinos);
        processEquinosPoblacion(equinos)
      } catch (error) {
        console.error('Error fetching equinos:', error);
      }
    };

    fetchEquinos();
  }, []); // El segundo parámetro (una matriz vacía) indica que este efecto se ejecutará solo una vez al montar el componente.
 



console.log("equinosPoblacion",equinosPoblacion)



function processEquinosPoblacion(equinosPoblacion) {
   const legendaryEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Legendary");
   const mythicEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Mythic");
   const epicEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Epic");
   const spectralEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Spectral");
   const rareEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Rare");
   const uncommonEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Uncommon");
   const commonEquinos = equinosPoblacion.filter(equino => equino._id.tipo === "Common");
   // ... Repite para otros tipos de caballo

    setEquinosPoblacionL(legendaryEquinos);
     setEquinosPoblacionM(mythicEquinos);
      setEquinosPoblacionE(epicEquinos);
       setEquinosPoblacionS(spectralEquinos);
        setEquinosPoblacionR(rareEquinos);
         setEquinosPoblacionU(uncommonEquinos);
          setEquinosPoblacionC(commonEquinos);

    const poblacionfL=legendaryEquinos[0].cantidad
    const poblacionmL=legendaryEquinos[1].cantidad
   console.log("equinosPoblacionL", legendaryEquinos);
   console.log("equinosPoblacionL", legendaryEquinos[0].cantidad);
   console.log("equinosPoblacionL", legendaryEquinos[1].cantidad);
   console.log("equinosPoblacionM", mythicEquinos);
   console.log("equinosPoblacionE", epicEquinos);
}






 return (

    <div className="wrappPresale">
    <Especial/>
  <div className="tableroPresale"> 

   {equinosPoblacionL.length > 0 && (
      <Card
        img={ballo11}
        className="cardPs__image"
        title="Legendary Horse"
        description="Legendary Horse"
        mmemo="Purchase of Equine NFT - Rarity: Legendary | Block Horse Racing"
        amount="100.000"
        item_name="Legendary Equine"
        poblacionF={equinosPoblacionL[0].cantidad}
        poblacionM={equinosPoblacionL[1].cantidad}
        total="160"
          />
      )}

    {equinosPoblacionM.length > 0 && (
      <Card
        img={ballo12}
        className="nidea"
        title="Mythic"
        description="Mythic Horse"
        mmemo="Purchase of Equine NFT - Rarity: Mythic | Block Horse Racing"
        amount="75.000"
        item_name="Mythic Horse"
        poblacionF={equinosPoblacionM[0].cantidad}
        poblacionM={equinosPoblacionM[1].cantidad}
        total="300"
         
      />
)}
   {equinosPoblacionE.length > 0 && (
      <Card
        img={ballo13}
        className="cardPs__image"
        title="Epic"
        description="Epic Horse"
        mmemo="Purchase of Equine NFT - Rarity: Epic | Block Horse Racing"
        amount="60.000"
        poblacionF={equinosPoblacionE[0].cantidad}
        poblacionM={equinosPoblacionE[1].cantidad}
        total="450"
         
      />
)}
   {equinosPoblacionS.length > 0 && (
      <Card
        img={ballo14}
        className="cardPs__image"
        title="Spectral Horse"
        description="Spectral Horse"
        mmemo="Purchase of Equine NFT - Rarity: Spectral | Block Horse Racing"
        amount="40.000"
        item_name="Spectral Equine"
        poblacionF={equinosPoblacionS[0].cantidad}
        poblacionM={equinosPoblacionS[1].cantidad}
        total="600"
         
      />

)}

   {equinosPoblacionR.length > 0 && (
          <Card
        img={ballo15}
        className="cardPs__image"
        title="Rare Horse"
        description="Rare"
        mmemo="Purchase of Equine NFT - Rarity: Rare| Block Horse Racing"
        amount="20.000"
        item_name="Rare Horse"
        poblacionF={equinosPoblacionR[0].cantidad}
        poblacionM={equinosPoblacionR[1].cantidad}
        total="700"
         
      />
)}
   {equinosPoblacionU.length > 0 && (
          <Card
        img={ballo16}
        className="cardPs__image"
        title="Uncommon Horse"
        description="Uncommon Horse"
        mmemo="Purchase of Equine NFT - Rarity: UnCommon | Block Horse Racing"
        amount="12.500"
        item_name="UnCommon Horse"
        poblacionF={equinosPoblacionU[0].cantidad}
        poblacionM={equinosPoblacionU[1].cantidad}
        total="800"
         
      />
)}
   {equinosPoblacionC.length > 0 && (
          <Card
        img={ballo17}
        className="cardPs__image"
        title="Common Horse"
        description="Common Horse"
        mmemo="Purchase of Equine NFT - Rarity: Common| Block Horse Racing"
        amount="5.000"
        item_name="Common Horse"
        poblacionF={equinosPoblacionC[0].cantidad}
        poblacionM={equinosPoblacionC[1].cantidad}
        total="800"
         
      />
  )}  
      
   </div></div>
  );
}


function Card(props) {

const [formData, setFormData] = useState({
    memo: props.mmemo || '',
    amount: props.amount || '',
  });





const handleSubmit = (e) => {
    e.preventDefault();
    handlebuyVenta();
  };



const getExchangeRate = async () => {
  try {
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=hive&vs_currencies=usd');
    const data = await response.json();
    return data.hive.usd; // Obtén el precio en USD
  } catch (error) {
    console.error('Error al obtener el precio de HIVE:', error);
    return null;
  }
};


 const handlebuyVenta = async () => {
  const memo = formData.memo;
  const toUsername = "bhr-especiapackl";
  const username = localStorage.getItem("user");
  
  const exchangeRate = await getExchangeRate();
  if (!exchangeRate) {
    alert('No se pudo obtener la tasa de cambio.');
    return;
  }
  
  const amountInUSD = formData.amount;
  const amount = (amountInUSD / exchangeRate).toFixed(3); // Calcula el monto en tokens SWAP.HIVE
  
  if (window.hive_keychain) {
    const keychain = window.hive_keychain;
    keychain.requestSendToken(username, toUsername, amount, memo, 'SWAP.HIVE', (response) => {
      console.log("RES KeyC", response);
      axios.post(`${linkApp}/keychainchestbhrt`, response)
        .then(res => {
          console.log("Response from backend", res);
          // Manejar la respuesta del backend si es necesario
        })
        .catch(error => {
          console.log("Error de post axios", error);
          // Manejar cualquier error que ocurra durante la llamada a la API
        });
    });
  } else {
    alert('You do not have hive keychain installed');
  }
};

    return (
     
 <div className="card">
 <div className="box">
 <div className="content">
 
 <img src={props.img} alt="Horses"  />
 <div className="price-tag"><h5 style={{color: '#ffd700', fontSize: '1.2em'}}>Price: $ {Number(props.amount)}</h5></div>

  <div className="presale-info">
  <h5 style={{ color: '#222', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontSize: '1.4em',fonWweight: 'bold', backgroundColor: '#B8860B' }}>{props.description}</h5>

  <form onSubmit={handleSubmit}>
  <input type="hidden" name="mmemo" value={props.mmemo}/>
  <input type="hidden" name="amount" value={props.amount}/>
  <h4 style={{ color: '#2196f3', textShadow: '0.1em 0.1em 0.2em rgba(0, 0, 0, 0.5)', fontSize: '1em', backgroundColor: '#222' }}> ♀️ {props.poblacionF} / ♂️ {props.poblacionM}</h4>
  <h5 style={{ color: '#2196f3', textShadow: '0.1em 0.1em 0.2em rgba(0, 0, 0, 0.5)', fontSize: '1em', backgroundColor: '#222' }}>{props.poblacionF + props.poblacionM} / {props.total} </h5>
<input type="submit" className="submit-presale" name="background"  value="BUY"/>
  </form>


    </div>
    </div>
    </div>
    </div>
    
  );
}

export default Presale